import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Helmet } from 'react-helmet'
import Sidebar from "../components/Sidebar"
import Newsletter from '../components/Newsletter'

export default function Post( { data } ) {

  const { body } = data.mdx
  const { title, dateCreated, tags, lead, ogImg, postUrl, description } = data.mdx.frontmatter

  return (
    <Layout>
      <Helmet>
        <title>{ title } | Use Less Divs</title>

        <meta name="description" content={ description } />
        <meta property="og:title" content={ title } />
        <meta property="og:description" content={ description } />
        <meta property="og:image" content={ ogImg } />
        <meta property="og:image:alt" content="Use Less Divs" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ postUrl } />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@JocoPolgar" />
        <meta name="twitter:creator" content="@JocoPolgar" />
        <meta name="twitter:url" content={postUrl} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={ ogImg } />
        <link rel="canonical" href={ postUrl } />
      </Helmet>
      <main className="blog-post">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <h1 className="h1">{ title }</h1>
              <p><time className="article__date">{ dateCreated }</time>{ tags? <span className="hashtag">{ tags }</span> : null}</p>
              <p className="blog-post__lead">{ lead }</p>
              <MDXRenderer>{body}</MDXRenderer>
              <Newsletter />
            </div>
            <Sidebar />
          </div>
        </div>
      </main>
    </Layout>
  )
}
export const query = graphql`
  query blogPost($slug: String) {
    mdx(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        title
        slug
        dateCreated(formatString: "DD. MMMM YYYY.")
        lead
        tags
        ogImg
        postUrl
        description
      }
      body
    }

    site {
      siteMetadata {
        description
        ogimg
        title
        url
      }
    }
  }
`